import React, { useEffect, useState } from 'react';

// material-ui
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
// project imports
import NavGroup from './NavGroup';
import menuItem from './../../../../menu-items';
import axios from 'axios';
import config from '../../../../config';
//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const MenuList = () => {
    
    //let authMenu = menuItem;
    const account = useSelector((state) => state.account);
    const [loaded, setLoaded] = useState(false)
    const [newMenuItems, setNewMenuItems] = useState([])
    useEffect( ()=> {
    const { permissions, authMenu } = account;
    if (!loaded){
        
        console.log('auth menulist', authMenu)
        console.log('auth permission', permissions)
        if (authMenu){
            setNewMenuItems(authMenu)
        }
        
        setLoaded(true)
    }
})
    //const newMenuItems = menuItem  
    //console.log('full menu', authMenu)
    const navItems = newMenuItems.map((item) => {
        switch (item.type) {
            case 'group':
               return <NavGroup key={item.id} item={item} />;
            
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return navItems;
};

export default MenuList;
